import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Text, Wrappers } from "@features/components";
import { Box, Stack, useMediaQuery } from "@mui/material";
import heroImage from "../../../assets/heroImage.png";
import subtitleImage from "../../../assets/subtitleImage.png";
import { ReactComponent as WhatsAppIcon } from "../../../assets/whatsapp-icon.svg";
import useButton from "./hooks/useButton";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingBottom: "82px",

        [theme.breakpoints.down("sm")]: {
            paddingBottom: "50px"
        }
    },
    section: {
        height: "700px",
        width: "100vw",
        marginLeft: "-24px",
        // background: "#F7F7F7",
        borderRadius: 0,
        padding: "0 152px",

        "@media screen and (max-width: 1375px)": {
            padding: "0 60px",
            height: "650px"
        },

        [theme.breakpoints.down("md")]: {
            position: "relative",
            height: "fit-content",
            padding: "0 16px 72px"
        },

        [theme.breakpoints.down("sm")]: {
            marginLeft: "-16px"
        }
    },
    sectionHuge: {
        minWidth: "100vw",
        marginLeft: "calc((100vw - 1696px) / -2)"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "1728px",
        margin: "0 auto",

        [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse"
        }
    },
    textContainer: {
        maxWidth: "750px",
        marginTop: "146px",

        [theme.breakpoints.down("md")]: {
            // marginTop: "48px",
            marginTop: "16px",
            maxWidth: "100%"
        }
    },
    imageContainer: {
        maxHeight: "700px",
        position: "relative",

        [theme.breakpoints.down("md")]: {
            "& img": {
                padding: "0 16px"
            }
        }
    },
    whatsAppIcon: {
        height: "37px",
        margin: "0 0 -5px 8px"
    },
    image: {
        // height: "782px",
        height: "700px",
        width: "693px",
        // paddingTop: "80px",
        objectFit: "contain",

        "@media screen and (max-width: 1800px)": {
            height: "680px"
        },

        "@media screen and (max-width: 1500px)": {
            // height: "700px"
            height: "800px"
        },

        "@media screen and (max-width: 1375px)": {
            width: "553px",
            height: "650px"
        },

        "@media screen and (max-width: 1160px)": {
            width: "453px",
            height: "620px"
        },

        [theme.breakpoints.down("md")]: {
            height: "550px",
            width: "100vw",
            marginLeft: "-16px"
        },

        [theme.breakpoints.down("sm")]: {
            height: "350px"
        }
    },
    subtitleImage: {
        width: "300px"
    },
    button: {
        background: "none",
        border: "2px solid #000",
        width: "220px",
        fontSize: "14px",
        color: "#000",
        padding: "8px",
        textTransform: "none",

        "& svg": {
            height: "21px"
        },

        "&:hover": {
            background: "#000",
            color: "#fff"
        },

        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    text: {
        // textAlign: "start",
        fontWeight: 500,

        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            fontSize: "18px"
        }
    },
    title: {
        fontWeight: "600 !important",

        "& span": {
            textShadow: "0px 0px 1px black",
            fontSize: "59px"
        },

        "@media screen and (max-width: 1435px)": {
            fontSize: "42px !important",

            "& span": {
                fontSize: "43px !important"
            }
        },

        "@media screen and (max-width: 1160px)": {
            fontSize: "34px !important",

            "& span": {
                fontSize: "35px !important"
            }
        },

        [theme.breakpoints.down("sm")]: {
            fontWeight: "500 !important",
            fontSize: "34px !important",

            "& span": {
                fontSize: "35px !important"
            }
        }
    },
    subtitleContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "150px",
        paddingBottom: "150px",
        zIndex: 1,

        "& h1, h2": {
            textAlign: "center"
        },

        [theme.breakpoints.down("sm")]: {
            // paddingTop: "100px"
            paddingTop: "50px",
            paddingBottom: "0px"
        }
    }
}));

function HeroSection() {
    const classes = useStyles();
    // const handleClick = useButton();
    const isHugeScreen = useMediaQuery("(min-width:1728px)");
    const isMediumScreen = useMediaQuery("(max-width:1375px)");

    const handleClick = () => {
        window.open(
            "https://api.whatsapp.com/send/?phone=447947125679&text=I+want+to+sell+my+iphone&type=phone_number&app_absent=0",
            "_blank",
            "location=yes,height=785,width=800,scrollbars=yes,status=yes"
        );
    };

    return (
        <Box className={classes.wrapper}>
            <Wrappers.Container
                classes={{ root: `${classes.section} ${isHugeScreen && classes.sectionHuge}` }}>
                <Box className={classes.flexContainer}>
                    <Stack spacing={5} className={classes.textContainer}>
                        <Stack spacing={2} rowGap={{xs: 0, md: 2}}>
                            {/* <Text.Typography
                                variant='h1'
                                className={`${classes.text} ${classes.title}`}>
                                Unlimitid's Al magic will convert your clutter into cash
                                automatically
                            </Text.Typography>
                            <Text.Typography variant='body2' className={classes.text}>
                                We resell your products on existing marketplaces and deal 
                                <br />
                                with all the hassle. Just sit back and ship to the buyer. 
                                <br />
                                WhatsApp us now to start!
                            </Text.Typography> */}
                            <Text.Typography
                                variant='h1'
                                className={`${classes.text} ${classes.title}`}>
                                Get cash for your phone, laptop 
                                or tablet instantly through WhatsApp
                                <br />
                                {/* <span>
                                    WhatsApp
                                    <WhatsAppIcon className={classes.whatsAppIcon} />
                                </span> */}
                            </Text.Typography>
                            <Text.Typography variant='body2' className={classes.text}>
                                {/* Say goodbye to the hassle of lengthy listings, tedious
                                {!isMediumScreen ? <br /> : " "}
                                negotiations, and shipping complications. We take
                                {!isMediumScreen ? <br /> : " "}
                                care of everything */}
                                No more lengthy listings, or hunting around 
                                {!isMediumScreen ? <br /> : " "}
                                for valuations. We take care of everything 
                                {!isMediumScreen ? <br /> : " "}
                                and get you the best price in seconds
                            </Text.Typography>
                        </Stack>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleClick}
                            classes={{ root: classes.button }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "8px"
                                }}>
                                Discover more
                                <WhatsAppIcon />
                            </Box>
                        </Button>
                    </Stack>
                    <Box className={classes.imageContainer}>
                        <img src={heroImage} alt='hero' className={classes.image} />
                    </Box>
                </Box>
                <Box className={classes.subtitleContainer}>
                    <Stack spacing={{ xs: 4, md: 8 }} alignItems='center'>
                        <Stack>
                            <Text.Typography
                                variant='h1'
                                className={`${classes.text} ${classes.title}`}>
                                How it works
                            </Text.Typography>
                            <Text.Typography variant='h3' align='center' className={classes.text}>
                        
                                Unlimitid’s AI magic finds you the best price 
                                in seconds! Just send us a few 
                                {!isMediumScreen ? <br /> : ""}
                                pics via
                                <span
                                    style={{
                                        marginLeft: "7px",
                                        textShadow: "0px 0px 1px #000"
                                    }}>
                                    WhatsApp
                                </span>
                            </Text.Typography>
                        </Stack>
                        <img
                            src={subtitleImage}
                            alt='subtitle-hero'
                            className={classes.subtitleImage}
                        />
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleClick}
                                classes={{ root: classes.button }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        columnGap: "8px"
                                    }}>
                                    Discover more
                                    <WhatsAppIcon />
                                </Box>
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Wrappers.Container>
        </Box>
    );
}

export default HeroSection;
