import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useAuthentication } from "@features/user";
import config from "./config";
import { useSelector } from "react-redux";
import IndexPage from "./pages/index";
import AboutPage from "./pages/about";
import AdminPage from "./pages/admin";
import ConnectStripe from "./pages/my-account/connect-stripe";
import ProfileOrdersPage from "./pages/my-account/orders";
import CardPage from "./pages/card";
import EditCardPage from "./pages/card/editCard";
import CardSellerPage from "./pages/card/cardSeller";
import CartPage from "./pages/cart";
import CheckoutPage from "./pages/checkout";
import ForgotPasswordPage from "./pages/forgotPassword";
import RegisterBuyerPage from "./pages/registerBuyer";
import LoginPage from "./pages/login";
import MarketplacePage from "./pages/marketplace";
import { MintTokenPage, LazyMintTokenPage } from "./pages/mint";
import LazyMintWithAttributesPage from "./pages/mint/attributes";
import DashboardPage from "./pages/my-account/my-profile";
import ProfilePage from "./pages/my-account/profile";
import RegisterPage from "./pages/register";
import {
    AllRoyaltiesPage,
    AllRoyaltyTransactionsPage,
    FVWalletRoyaltyPage,
    UserWalletRoyaltyPage
} from "./pages/royalties";
import EmailVerifier from "./pages/verifiers/email";
import LazyTokenClaimPage from "./pages/verifiers/lazyTokenClaim";
import PasswordReset from "./pages/verifiers/passwordReset";
import MainNavigation from "./navigations";
import {
    MY_PROFILE,
    PROFILE,
    PROFILE_ORDERS,
    PROFILE_SETTINGS,
    EDIT_PROFILE,
    LOGIN,
    HOME_URL,
    REGISTER,
    CLAIM_NFT,
    CARD,
    CART,
    CHECK_OUT,
    BUYER_REGISTER,
    CONNECT_STRIPE,
    CARD_SELLER,
    EDIT_CARD,
    RESET_PASSWORD
} from "./pages.constants";
import { Footer } from "@features/components";
import FilterPage from "./pages/filter";
import ProfileSettings from "./pages/my-account/profile-settings";

function ProtectedRoute({ auth, children }) {
    if (!auth) {
        return <Navigate to={LOGIN} />;
    }
    return children;
}

function MainRouter() {
    const { authenticated } = useAuthentication();
    return (
        <BrowserRouter basename={config.server.basePath}>
            <MainNavigation auth={authenticated} />
            <Routes>
                <Route path={HOME_URL} element={<IndexPage />} />
                <Route
                    path={MY_PROFILE}
                    element={
                        <ProtectedRoute auth={authenticated}>
                            <DashboardPage />
                        </ProtectedRoute>
                    }
                >
                    <Route path={PROFILE} element={<ProfilePage />} />
                    <Route path={PROFILE_ORDERS} element={<ProfileOrdersPage />} />
                    <Route path={EDIT_PROFILE} element={<ProfilePage />} />
                    <Route path={PROFILE_SETTINGS} element={<ProfileSettings />} />
                    <Route path={CONNECT_STRIPE} element={<ConnectStripe />} />
                </Route>

                {
                    <React.Fragment>
                        <Route path='/marketplace' element={<MarketplacePage />} />
                        <Route path='/royalties'>
                            <Route path='my-royalties' element={<UserWalletRoyaltyPage />} />
                            <Route
                                path={config.branding.company.toLowerCase()}
                                element={<FVWalletRoyaltyPage />}
                            />
                            <Route path='transactions' element={<AllRoyaltyTransactionsPage />} />
                            <Route index element={<AllRoyaltiesPage />} />
                        </Route>
                        <Route path='/about' element={<AboutPage />} />
                    </React.Fragment>
                }
                {
                    <Route path='/mint'>
                        <Route path='web3' element={<MintTokenPage />}></Route>
                        <Route path='web2' element={<LazyMintTokenPage />}></Route>
                        <Route path='attributes' element={<LazyMintWithAttributesPage />}></Route>
                    </Route>
                }
                <Route path={LOGIN} element={<LoginPage />} />
                <Route path={REGISTER} element={<RegisterPage />} />
                <Route path={RESET_PASSWORD} element={<ForgotPasswordPage />} />
                <Route path='/filters' element={<FilterPage />} />
                <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                <Route path='/admin' element={<AdminPage />} />
                {/* Verifier pages */}
                <Route path='/email/verify' element={<EmailVerifier />} />
                <Route path='/password/reset' element={<PasswordReset />} />
                <Route path={CLAIM_NFT} element={<LazyTokenClaimPage />} />
                <Route
                    path={EDIT_CARD}
                    element={
                        <ProtectedRoute auth={authenticated}>
                            <EditCardPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CARD_SELLER}
                    element={
                        <ProtectedRoute auth={authenticated}>
                            <CardSellerPage />
                        </ProtectedRoute>
                    }
                />
                <Route path={CARD} element={<CardPage />} />
                <Route
                    path={CART}
                    element={
                        <ProtectedRoute auth={authenticated}>
                            <CartPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CHECK_OUT}
                    element={
                        <ProtectedRoute auth={authenticated}>
                            <CheckoutPage />
                        </ProtectedRoute>
                    }
                />
                <Route path={BUYER_REGISTER} element={<RegisterBuyerPage />} />
                <Route
                    path={"*"}
                    element={

                        <img
                            src='https://coreldrawdesign.com/resources/previews/preview-error-404-page-not-found-page-vector-template-1629006906.jpg'
                            width={"100%"}
                        ></img>
                    }
                />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export function useRestoreStateOnAuthenticatedOrRedirect(redirect) {
    const navigate = useNavigate();
    const { authenticated } = useAuthentication();
    const location = useLocation();
    const historyStateReferer =
        useSelector((state) => state?.app?.navigation?.state?.referer) || location?.state?.referer;
    useEffect(() => {
        if (authenticated) {
            historyStateReferer ? navigate(historyStateReferer) : navigate(redirect);
        }
    }, [location, redirect, authenticated, historyStateReferer]);
}

export function useRedirectOnAuthenticated(redirect) {
    const navigate = useNavigate();
    const { authenticated } = useAuthentication();

    useEffect(() => {
        if (authenticated) {
            navigate(redirect);
        }
    }, [navigate, redirect, authenticated]);
}

export default MainRouter;
