import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import NotificationCenter from "@features/notifications/center";
import MainRouter from "@app/router";
import config from "@app/config";
import { RESET } from "@app/store";
import { useAuthentication } from "@features/user";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ElfsightWidget } from "react-elfsight-widget";
import ReactGA from "react-ga4";

function App() {
    const dispatch = useDispatch();

    // initialize google analytics using react-ga4
    useEffect(() => {
        ReactGA.initialize(config.googleAnalyticsId)
    }, []) 

    // Clears out local storage when client version changes.
    // obsolete when using session storage, since session storage is cleared automatically between sessions.
    // this code will only help with local storage where we may cache things for extended periods of time.
    // such example would be a list of blockchain networks.
    const lastVersion = useSelector((state) => state.version);
    useEffect(() => {
        if (config.version !== lastVersion) {
            console.log(
                "application version",
                lastVersion,
                "is outdated.\nlatest: ",
                config.version,
                " will reset to storage to initial"
            );
            dispatch({ type: RESET });
        }
    });

    // When page loads and user is not authenticated try and refresh token
    // if refresh fails no http-only secure refresh cookie exists and user must authenticate manually.
    // if it returns success it will pass back a new jwt which can be used to authenticate the user.
    //
    // We must block router until we have refreshed token
    // otherwise router will redirect unauthorized user to the login page before refresh is complete.
    // which will then authorize the user back to the users dashboard.
    // For obvious reasons this is bad for UX.
    const { authenticated, preventRefresh, refresh } = useAuthentication();
    const [ready, setReady] = useState(false);
    useEffect(() => {
        async function refreshToken() {
            if (!authenticated && !preventRefresh) {
                console.log("refreshing");
                await refresh();
            }
            setReady(true);
        }
        refreshToken();
    }, [authenticated, refresh, preventRefresh, dispatch]);

    // useEffect(() => {
    //     const script = document.createElement("script");

    //     script.innerText = `
    //         var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    //         (function(){
    //         var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    //         s1.async=true;
    //         s1.src='https://embed.tawk.to/65a622c98d261e1b5f53ce4e/1hk8ffpk3';
    //         s1.charset='UTF-8';
    //         s1.setAttribute('crossorigin','*');
    //         s0.parentNode.insertBefore(s1,s0);
    //         })();
    //     `;

    //     document.body.appendChild(script);

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);

    return (
        <Box sx={{ flexGrow: 1, minHeight: "100vh", overflowX: "hidden" }}>
            {ready && <MainRouter />}
            <NotificationCenter />
            <ElfsightWidget widgetId="49d3186e-2e9c-494a-9955-b1811644f367" />
        </Box>
    );
}

export default App;
